/* eslint-disable jsx-a11y/anchor-has-content */

import React, { useEffect, useState, useCallback, Fragment, useRef } from 'react'
import { graphql } from 'gatsby'
// https://codebushi.com/gatsby-featured-images/
import { useClasses } from '../lib/ReactUtils'
import AnchorLink from '../components/AnchorLink'
import SEO from '../components/SEO'
import { setOpen } from '../components/Menu'
import { throttle, union, clone, intersection, without, omit } from 'lodash'
import ChevronRight from "../svg/ChevronRight"
import '../styles/Prose'
import Cookies from "../lib/Cookies"

const CONTEXTS = [ 'writer','impress','calc','chart','draw','base','default']

export default function Doc({ data: { doc }, pageContext: { breadcrumb } }) {
  const { title, context:_context } = doc.frontmatter
  const [contexts, setContexts] = useState([])
  const [context, setContext] = useState('default')
  const [current, setCurrent] = useState(doc.frontmatter.headings?.length ? doc.frontmatter.headings[0].id : null)
  const root = useClasses()
  const post = useRef()

  const allowThirdLevel = doc.headings.length < 30
  const headings = doc.headings.filter(function ({value, depth}) {
    return !/\</.test(value) &&  (depth == 2 || (allowThirdLevel && depth == 3))
  })

  useEffect(() => {
    setCurrent(headings[0]?.id)
  }, [])

  useEffect(() => {
    if (_context) {
      Cookies.setItem('context', _context)
      setContext(_context)
    }
    else {
      setContext(Cookies.getItem('context'))
    }
  }, [_context])

  useEffect(() => {
    let selectors = clone(CONTEXTS) 
    if (context) {
      let index = selectors.indexOf(context)
      if (index != -1) selectors.splice(index,1)
    }
    let found = []
    let nodes = post.current.querySelectorAll('.'+selectors.join(',.'))
    nodes.forEach(function(node) {
      node.classList.remove('current')
      found = union(found, Array.from(node.classList))
    })
    nodes = post.current.querySelectorAll('.'+context)
    nodes.forEach(function(node) {
      node.classList.add('current')
      found = union(found, [context])
    })
    if (found.length && !found.includes(context)) setContext('default')
    setContexts(intersection(CONTEXTS, without(found,'default')))
  }, [context])


  const onScroll = useCallback(throttle(() => {
    let bottom = document.body.scrollTop + window.innerHeight
    let _current
    headings.forEach(({ id }) => {
      let section = document.getElementById(id)
      if (!section) return
      if (section.offsetTop < bottom) _current = id
    })
    if (_current) {
      setCurrent(_current)
    }
  }), [])

  const onClick = (e) => {
    const _context = e.currentTarget.getAttribute('data-context')
    if (_context == context) {
      setContext('default')
      Cookies.setItem('context', 'default')
    }
    else {
      setContext(_context)
      Cookies.setItem('context', _context)
    }
  }

  useEffect(() => {
    document.body.addEventListener("scroll", onScroll)
    return () => document.body.removeEventListener("scroll", onScroll)
  }, [])

  useEffect(() => {
    if (!breadcrumb) return
    let titles = []
    let current = ''
    breadcrumb.forEach(function (element) {
      if (current) current += '-'
      current += element[0]
      titles.push(current)
    })
    setOpen(titles)
  }, [breadcrumb])

  return (
    <>
      <SEO
        title={title}
        article
      />
      <div ref={root}>
        {breadcrumb && breadcrumb.length ?
          <nav className="bg-sky-100 text-sm md:text-base px-5 py-3 mt-20 md:mt-8 -ml-2 rounded-md w-full text-primary-dark font-semibold">
            <div>
              {breadcrumb.map((data, index) =>
                <Fragment key={index}>
                  <span>{data}</span>
                  <span><ChevronRight /></span>
                </Fragment>
              )}
              <span>{title}</span>
            </div>
          </nav> : null
        }
        {contexts.length ? 
          <div className='flex gap-x-1 h-10 mt-3 mb-3'>
            {contexts.map((icon) => 
              <img
                key={icon}
                data-context={icon} src={'/'+icon +'.svg'}
                className={context == icon ? 'opacity-100 border-2 border-primary-dark rounded cursor-pointer' : 'opacity-40 border-2 border-white cursor-pointer'}
                onClick={onClick}
              />
            )}
          </div> : null
        }
        <div className='flex flex-row md:gap-x-10 md:min-h-screen'>
          <div ref={post} className='flex-grow-1 w-full prose pb-25 md:pb-15 overflow-x-hidden mt-10' dangerouslySetInnerHTML={{ __html: doc.html }} />
          {headings.length > 1 ?
            <div className='hidden lg:block w-40 xl:w-70'>
              <div className='sticky top-5 w-full flex flex-col gap-y-2 pr-2 pt-6'>
                <span className='text-base xl:text-lg font-semibold mb-1'>{title}</span>
                {headings.map(({ value, depth, id }) => {
                  return (
                    <AnchorLink
                      className={
                        'basic tracking-snug leading-tight ' +
                        (depth == 3 ? 'text-xs xl:text-sm pl-2 ' : 'text-sm xl:text-base ') +
                        (current === id ? 'font-semibold tracking-tight text-orange-700' : 'font-thin tracking-snug hover:text-primary transition duration-200')
                      }
                      to={id}
                      key={id}
                    >
                      {value}
                    </AnchorLink>
                  )
                })}
              </div>
            </div> : null
          }
        </div>
      </div>
    </>
  )
}

export const postQuery = graphql`
  query DocBySlug($slug: String!) {
    doc: markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      id
      html
      frontmatter {
        title
        slug
        context
      }
      headings {
        id
        value
        depth
      }
    }
  }
`
