import { addCss } from '../lib/ReactUtils'

addCss(`
  .prose {
    @apply text-lg text-gray-800
  }
  .prose strong {
    @apply font-bold
  }
  .prose p, .prose div {
    @apply leading-snug mb-2
  }
  body {
    @apply text-gray-800
  }
  .prose h1 {
    @apply text-4xl font-black mt-14 mb-3 text-primary-dark tracking-snug leading-snug
  }
  .prose h2 {
    @apply text-2xl font-bold mt-8 mb-1 tracking-tight leading-snug text-sky-900
  }
  .prose h3 {
    @apply text-xl font-semibold mt-5 mb-1 leading-snug tracking-tight
  }
  .prose h4 {
    @apply text-xl font-semibold mt-5 mb-1 leading-tight tracking-tight text-primary-dark
  }
  .prose ul {
    @apply list-disc list-outside
  }
  .prose ol {
    @apply list-decimal	list-outside
  }
  .prose ol:not(.mt-0), ul:not(.mt-0) {
    @apply my-2
  }
  .prose li {
    @apply ml-7 lg:ml-9 pl-0 mt-1.5
  }
  .prose li p {
    @apply mt-0 inline leading-snug
  }
  .prose table {
    @apply mt-4 mb-8 w-full
  }
  .prose thead th {
    @apply text-left py-2 bg-gray-100
  }
  .prose tbody tr {
    @apply border-t-2 last:border-b-2 border-gray-200
  }
  .prose td {
    @apply text-sm last:pr-0 pr-2 py-2
  }
  .prose blockquote {
    @apply my-4 py-3 pr-2 font-normal border-l-5 border-primary-dark border-t-1 border-r-1 border-b-1 rounded-r bg-sky-100/50 text-primary-dark pl-3 
  }
  .prose blockquote p, .prose blockquote h4 {
    @apply my-0
  }
  .prose img:not(.icon) {
    @apply my-6 mx-auto
  }
  .prose code {
    @apply max-w-100
  }
  .writer:not(.current), .impress:not(.current), .calc:not(.current), .base:not(.current), .draw:not(.current), .chart:not(.current), .default:not(.current) {
    display: none;
  }
`)

